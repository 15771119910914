<template>
  <HomeSouthAfrica v-if="isZa" />
  <HomeUS v-else />
</template>
<script setup lang="ts">
  const { isZa } = useSite()
  definePageMeta({
    middleware: ['sosovalue','ip2location'],
  })
</script>
