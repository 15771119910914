<template>
  <div class="tw-relative tw-flex tw-flex-col tw-space-y-[60px]">
    <section class="tw-relative tw-z-0 tw-text-white after:tw-home-right-bg before:tw-home-left-bg">
      <CommonHeader is-light />
      <section class="tw-container tw-mx-auto">
        <div
          class="before:tw-absolute before:tw-content-[''] before:tw-bg-[#0a0a16] before:tw-top-0 before:tw-left-0 before:tw-h-[125%] before:tw-w-full before:-tw-z-[2] lg:tw-home-bg"
        >
          <div class="tw-relative after:tw-home-banner-bg tw-flex tw-flex-row tw-flex-wrap tw-py-0 sm:tw-py-5 md:tw-py-10 lg:tw-py-14">
            <div class="tw-basis-full tw-block lg:tw-hidden">
              <div class="tw-h-[268px] tw-relative after:tw-content-[''] after:tw-absolute after:tw-z-0 after:tw-home-h5-bg" />
            </div>
            <div class="tw-basis-full lg:tw-basis-5/12">
              <div
                class="tw-flex tw-flex-col tw-justify-center tw-items-center lg:tw-justify-start lg:items-start tw-text-center lg:tw-text-left tw-mb-5 sm:tw-mb-10 md:tw-mb-14 lg:tw-mb-20 tw-mt-5 sm:tw-mt-8 md:tw-mt-10 lg:tw-mt-15"
              >
                <i18n-t
                  keypath="unlock-the-future-of-finance-with-us"
                  tag="h2"
                  class="lg:tw-hidden tw-leading-[1.32] tw-text-3xl sm:tw-text-4xl md:tw-text-5xl lg:tw-text-6xl tw-font-bold"
                >
                  <br />
                </i18n-t>

                <h2 class="tw-hidden lg:tw-block tw-leading-[1.32] tw-text-3xl sm:tw-text-4xl md:tw-text-5xl lg:tw-text-6xl tw-font-bold">
                  {{ $t('unlock-the-future-of-finance-with-us') }}</h2
                >
                <!-- {{$(a.b)}} -->
                <i18n-t
                  keypath="the-leading-u-s-regulated-crypto-trading-firm-trusted-by-800-institutional-traders"
                  tag="p"
                  class="tw-leading-[1.5] tw-text-sm sm:tw-text-base md:tw-text-xl lg:tw-text-2xl tw-mt-5 md:tw-mt-6 lg:tw-mt-7 tw-font-normal"
                >
                  <span class="tw-text-primary">900+</span>
                </i18n-t>
                <div
                  class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-center lg:tw-justify-start tw-items-center tw-w-full tw-mt-7 sm:tw-mt-10 md:tw-mt-14 lg:tw-mt-20"
                >
                  <UIButton to="/kyc" theme="primary-2" class="tw-btn-lg md:tw-btn-xl xl:tw-btn-2xl tw-uppercase">
                    {{ $t('trade-with-us') }}
                  </UIButton>
                  <NuxtLinkLocale
                    to="/partner"
                    class="tw-text-white md:tw-text-primary tw-text-lg md:tw-text-xl tw-font-bold"
                    :class="[$style.link]"
                  >
                    <div>
                      {{ $t('or-become-a-partner') }}
                    </div>
                  </NuxtLinkLocale>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6
          class="tw-relative after:tw-content-[''] after:tw-absolute after:tw-z-50 after:tw-w-[7px] after:tw-h-[7px] after:-tw-top-[2px] after:tw-left-1/2 after:tw-bg-primary after:tw-transform after:tw-translate-x-[-50%] after:tw-rotate-45"
        >
          <div class="scss_darkToLight tw-h-[2px] md:tw-h-[1px]"></div>
        </h6>
        <section class="tw-text-center tw-mt-[60px] tw-mb-7 sm:tw-mb-14 md:tw-mb-20 lg:tw-mb-28">
          <h5 class="tw-text-primary tw-underline tw-text-lg md:tw-text-xl tw-font-black">{{ $t('why-legend-trading') }}</h5>
          <h3 class="tw-text-white tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-black tw-mt-5 md:tw-mt-6">
            {{ $t('professional-secure-and-regulatory-compliant') }}
          </h3>
          <p class="tw-text-white tw-mx-auto tw-mt-5 md:tw-mt-6 tw-max-w-[1200px] tw-text-base md:tw-text-lg">
            Legend Trading offers crypto trading with deep liquidity and competitive pricing, while developing next-generation global payment solutions powered by blockchain. Trusted by leading crypto platforms, we serve tens of millions of users directly and indirectly.
          </p>

          <section
            class="tw-text-center tw-rounded-[30px] tw-bg-white tw-text-[#333] tw-px-4 sm:tw-px-10 md:tw-px-20 lg:tw-px-40 tw-pb-5 sm:tw-pb-10 md:tw-pb-14 lg:tw-pb-20 tw-pt-5 md:tw-pt-6 lg:tw-pt-7 tw-mt-14 sm:tw-mt-16 md:tw-mt-18"
          >
            <h3 class="tw-text-xl md:tw-text-2xl tw-font-black">{{ $t('partners-and-clients') }}</h3>
            <p class="tw-text-base lg:tw-text-lg tw-mt-5">
              {{
                $t(
                  'legend-trading-is-trusted-by-over-800-institutional-customers-and-partners-globally-we-take-pride-in-delivering-exceptional-service-quality-and-security-fostering-long-term-relationships-with-our-clients',
                )
              }}
            </p>

            <i18n-t
              keypath="Trusted_by_the_best"
              tag="h5"
              class="tw-pb-5 md:tw-pb-8 lg:tw-pb-10 tw-pt-5 lg:tw-pt-8 tw-flex tw-justify-center tw-text-xl md:tw-text-2xl tw-font-black"
            >
              <span
                :class="{
                  'tw-italic tw-font-light tw-block sm:tw-inline tw-font-poppins': locale === 'en',
                }"
                >&nbsp;{{ $t('best') }}&nbsp;</span
              >
            </i18n-t>
            <partner-ships-home />
          </section>
        </section>
      </section>
    </section>

    <section class="tw-container tw-mx-auto">
      <section class="section2 tw-mb-14 md:tw-mb-16 lg:tw-mb-20">
        <div class="tw-rounded-[30px] tw-py-14 md:tw-py-16 lg:tw-py-20 section2__content">
          <div class="tw-flex tw-flex-row tw-flex-wrap">
            <template v-for="(item, index) in gridList" :key="index">
              <div class="tw-basis-full md:tw-basis-1/2">
                <div class="tw-max-w-[360px] tw-px-4 tw-py-7 sm:tw-py-8 md:tw-py-10 lg:tw-py-14 tw-mx-auto">
                  <div class="tw-flex tw-justify-center">
                    <NuxtImg :width="75" :height="75" :src="item.imgPath" :alt="item.altText" />
                  </div>
                  <h4 class="tw-text-white tw-mt-7 tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-bold" v-html="item.title" />
                  <article class="tw-text-sm md:tw-text-base tw-mt-2 sm:tw-mt-3 md:tw-mt-4 lg:tw-mt-5" v-html="item.subTitle" />
                </div>
              </div>
              <div v-if="index % 2 === 1 && index !== gridList.length - 1" class="tw-basis-full tw-hidden md:tw-block tw-overflow-visible">
                <h3 class="scss_darkToLight tw-h-[2px] md:tw-h-[1px]" />
                <h3 class="scss_darkToLight tw-h-[2px] md:tw-h-[1px] section2__content__v" />
              </div>
              <div width="100%" class="tw-w-full tw-block md:tw-hidden" v-if="index !== gridList.length - 1">
                <h3 class="scss_darkToLight tw-h-[2px] md:tw-h-[1px]" />
              </div>
            </template>
          </div>
        </div>
      </section>

      <section class="tw-mb-14 md:tw-mb-16 lg:tw-mb-20">
        <h5 class="tw-text-primary tw-font-bold tw-text-base md:tw-text-xl md:tw-text-center"> NEWSROOM </h5>
        <h3
          class="tw-text-dark tw-font-bold tw-text-[28px] tw-leading-tight tw-mx-auto tw-max-w-[32rem] tw-mt-5 md:tw-text-center md:tw-text-[2rem] md:tw-leading-normal"
        >
          {{ $t('our-latest-partnerships-and-announcements') }}
        </h3>
        <RepostCarousel />
      </section>

      <section class="tw-mb-14 md:tw-mb-16 lg:tw-mb-20">
        <div class="tw-overflow-hidden">
          <div class="lg:tw-px-20">
            <div class="tw-flex tw-flex-row tw-items-center tw-mb-14 sm:tw-mb-18 md:tw-mb-24 lg:tw-mb-28">
              <div class="tw-flex-1">
                <div class="text-left">
                  <div class="tw-flex tw-justify-between tw-items-end">
                    <h5 class="tw-text-primary tw-underline tw-text-lg md:tw-text-xl tw-font-black">
                      {{ $t('infrastructure') }}
                    </h5>
                    <div class="tw-w-[125px]">
                      <SvgoHomeCryptoinfrastrIcon class="sm:tw-hidden" />
                    </div>
                  </div>
                  <h3 class="tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-black tw-mt-5">
                    {{
                      $t(
                        'we-provide-a-payment-infrastructure-that-helps-connect-traditional-banking-systems-to-cryptocurrency-platforms-and-exchanges',
                      )
                    }}
                  </h3>
                  <p class="tw-text-base md:tw-text-lg tw-mt-5">
                    {{
                      $t(
                        'legend-trading-offers-a-payment-as-a-service-and-financial-services-for-crypto-exchanges-wallets-nft-marketplace-dapps-and-etc-to-bridge-the-gap-between-crypto-and-traditional-banking-system-by-integrating-a-few-lines-of-codes-youll-be-able-to-utilize-our-global-banking-and-trading-networks-and-offer-an-easy-way-for-your-users-to-buy-or-sell-cryptos-nfts-and-etc-with-bank-transfers',
                      )
                    }}
                  </p>
                  <div class="tw-mt-5">
                    <NuxtLinkLocale class="tw-text-lg md:tw-text-xl tw-font-black more" to="/legend-pay">
                      {{ $t('read-more') }}
                    </NuxtLinkLocale>
                  </div>
                </div>
              </div>
              <div class="tw-basis-1/3 tw-hidden sm:tw-block">
                <div class="tw-mx-auto tw-max-w-[250px] tw-min-w-[125px]">
                  <SvgoHomeCryptoinfrastrIcon />
                </div>
              </div>
            </div>
          </div>
          <div
            class="tw-rounded-[30px] lg:tw-rounded-[60px] tw-bg-[#f8f6f6] tw-px-4 tw-mb-14 md:tw-mb-16 lg:tw-mb-20 tw-py-14 md:tw-py-16 lg:tw-py-20"
          >
            <div class="lg:tw-px-20">
              <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-[30px] tw-items-center">
                <div>
                  <h5 class="tw-text-primary tw-underline tw-text-lg md:tw-text-xl tw-font-black">
                    {{ $t('legend-trading-payments-infrastructure') }}
                  </h5>
                  <h3 class="tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-black tw-mt-5">{{ $t('our-five-key-solutions') }}</h3>
                  <p class="tw-text-base md:tw-text-lg tw-mt-5">
                    {{
                      $t(
                        'we-take-pride-in-being-the-solution-you-need-to-thrive-in-todays-competitive-cryptocurrency-space-our-payments-infrastructure-solution-will-help-accelerate-the-broad-adoption-of-cryptocurrency',
                      )
                    }}
                  </p>
                </div>
                <div>
                  <div class="tw-flex tw-justify-end tw-relative -tw-right-5">
                    <NuxtImg src="/res/img/shadowbg/key-group.png" class="tw-w-full tw-max-w-[400px]" alt="key-group-icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="lg:tw-px-20">
            <div
              class="tw-flex tw-flex-row tw-items-center tw-mb-14 sm:tw-mb-18 md:tw-mb-24 lg:tw-mb-28 tw-relative after:tw-content-[''] after:tw-absolute after:tw-bg-no-repeat after:tw-bg-none after:xl:tw-bg-[url('/res/img/home/artwork.png')] after:tw-bg-right after:tw-bg-contain after:tw-w-full after:tw-h-[100%] after:2xl:tw-h-[150%]"
            >
              <div class="tw-basis-full xl:tw-basis-1/2 tw-relative tw-z-10">
                <div class="text-left">
                  <div class="tw-flex tw-align-bottom tw-justify-between tw-items-end">
                    <h5 class="tw-text-primary tw-underline text-left tw-text-lg md:tw-text-xl tw-font-black tw-flex-none">
                      {{ $t('platform') }}
                    </h5>
                    <!-- <div class="xl:tw-hidden tw-w-64 tw-h-32 md:tw-w-80 md:tw-h-40 tw-relative after:tw-content-[''] after:tw-absolute after:tw-bg-no-repeat after:tw-bg-[url('/res/img/home/artwork.png')]  after:tw-bg-left-top after:tw-bg-contain after:tw-w-[120%] after:tw-h-[120%]">
                    </div> -->
                  </div>
                  <h3 class="tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-black tw-mt-5">
                    {{ $t('a-leading-cryptocurrency-trading-platform-serving-institutional-and-high-net-worth-investors') }}
                  </h3>
                  <p class="tw-text-base md:tw-text-lg tw-mt-5">
                    {{
                      $t(
                        'legend-trading-is-a-us-regulated-cryptocurrency-trading-company-trusted-by-hundreds-of-leading-institutions-we-provide-you-access-to-our-network-of-over-30-exchanges-our-proprietary-trading-technology-will-provide-you-with-the-deep-liquidity-and-optimize-your-spreads-we-support-the-most-popular-cryptocurrency-and-provide-fiat-settlement-services-in-us-dollar-euro-british-pound-and-many-more',
                      )
                    }}
                  </p>
                  <NuxtImg src="/res/img/home/artwork.png" class=" -tw-ml-2 xl:tw-hidden"/>
                  <div class="xl:tw-mt-5 tw-text-lg md:tw-text-xl tw-flex tw-items-center tw-gap-2">
                    {{ $t('discover') }}
                    <NuxtLinkLocale class="more" to="/legend-pay">
                      <SvgoHomeLegendPayLogo class="tw-h-[14px] md:tw-h-4" />
                    </NuxtLinkLocale>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section class="chartline-gold tw-rounded-[30px] lg:tw-rounded-[60px]">
            <h5 class="tw-text-primary tw-underline tw-text-lg md:tw-text-xl tw-font-black tw-mb-14 md:tw-mb-16 lg:tw-mb-20">
              {{ $t('our-numbers') }}
            </h5>
            <div class="tw-w-3/4 tw-mx-auto tw-uppercase tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[30px]">
              <div cols="12" md="4" v-for="(item, index) in numberList" :key="index">
                <h1 class="tw-text-primary tw-text-6xl md:tw-text-7xl tw-font-black">
                  {{ item.prefix }}{{ item.endVal }}{{ item.suffix }}
                </h1>
                <h5 class="tw-text-white tw-mt-5 md:tw-mt-6 lg:tw-mt-7 tw-text-lg md:tw-text-xl">{{ item.subTitle }}</h5>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section class="tw-text-center tw-mb-14 sm:tw-mb-16 md:tw-mb-20 lg:tw-mb-24">
        <section
          class="tw-bg-dark chart-background tw-rounded-[30px] lg:tw-rounded-[60px] tw-py-14 md:tw-py-16 lg:tw-py-20 tw-px-5 sm:tw-px-14 md:tw-px-24 lg:tw-px-28"
        >
          <h3
            class="tw-text-lg md:tw-text-xl tw-font-black tw-mb-14 md:tw-mb-16 lg:tw-mb-20 tw-text-primary tw-underline !tw-leading-normal"
          >
            {{ $t('what-make-us-different') }}</h3
          >
          <div class="tw-flex tw-text-white tw-flex-col tw-space-y-14 md:tw-flex-row md:tw-space-x-6 md:tw-space-y-0">
            <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-between">
              <h3 class="tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-black">
                {{ $t('deep-liquidity-with-connectivity-to-over-70-exchanges') }}
              </h3>

              <p class="tw-text-base md:tw-text-lg tw-mt-5">
                {{
                  $t(
                    'by-leveraging-our-integrations-with-a-wide-range-of-markets-and-exchanges-our-proprietary-technology-provides-deep-liquidity-with-minimum-slippage-by-analyzing-data-points-on-pricing-and-volatility-in-milliseconds',
                  )
                }}
              </p>
            </div>
            <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-between">
              <h3 class="tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-black">
                {{ $t('seamless-settlement-services-in-usd-eur-gbp-and-other-major-fiat-currencies') }}
              </h3>
              <p class="tw-text-base md:tw-text-lg tw-mt-5">
                {{
                  $t(
                    'with-our-broader-local-banking-network-we-are-able-to-serve-large-crypto-fiat-trades-our-automatic-verification-and-settlement-service-allows-you-to-get-your-crypto-or-fiat-in-seconds',
                  )
                }}
              </p>
            </div>
          </div>
        </section>
      </section>

      <section class="tw-text-center tw-mb-14">
        <h3 class="tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-black">{{ $t('lets-get-started-today') }}</h3>
        <i18n-t
          keypath="the-leading-u-s-regulated-crypto-trading-firm-trusted-by-800-institutional-traders"
          tag="p"
          class="tw-mx-auto tw-text-base md:tw-text-lg tw-mt-5 sm:tw-mt-4 md:tw-mt-3 lg:tw-mt-2 tw-max-w-[416px]"
        >
          900+
        </i18n-t>
        <div class="tw-mt-7 md:tw-mt-8 lg:tw-mt-10">
          <UIButton to="/kyc" class="tw-btn-lg md:tw-btn-xl xl:tw-btn-2xl tw-uppercase">
            {{ $t('create-an-account') }}
          </UIButton>
        </div>
      </section>
    </section>
  </div>
</template>
<style scoped lang="scss">
  .scss_darkToLight {
    @include line(colors(dark), colors(light));
  }
  .more {
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    &::after {
      content: '';
      width: 11px;
      height: 17px;
      background: url('/res/img/home/more-arrow.svg');
      background-size: contain;
      margin-left: 8px;
    }
  }

  .section2 {
    &__content {
      background: linear-gradient(180deg, #19222d 0%, #0f1317 100%);
      text-align: center;
      position: relative;
      color: #fff;
      overflow: hidden;

      &__v {
        transform: rotate(90deg);
        width: 60% !important;
      }
    }
  }

  .chartline-gold {
    background: linear-gradient(180deg, #19222d 0%, #0f1317 100%);
    border-radius: 30px;
    padding: 60px 0;
    text-align: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 50%;
      max-height: 200px;

      transform: translate(-50%, -50%);
      background: url('/res/img/shadowbg/chartline-gold.svg') no-repeat 50% 50%;
      background-size: cover;
    }
  }

  .chart-background {
    background: url('/res/img/shadowbg/chart-background.jpg') no-repeat 50% 50%;
    background-size: cover;
  }

  .pay-background {
    position: relative;
    &::after {
      content: '';
      background: url('/res/img/home/artwork.png') no-repeat 100% 50%;
      background-size: contain;
      position: absolute;
      width: 100%;
      height: 120%;
    }
  }
</style>

<script lang="ts" setup>
  const { t, locale } = useI18n()

  const gridList = computed(() => [
    {
      title: t('global-liquidity'),
      subTitle: t('by-connecting-to-all-major-liquidity-providers-in-the-world-we-offer-excellent-liquidity-and-competitive-quotes'),
      imgPath: '/res/img/home/worldliquidity-icon.svg',
      altText: 'worldliquidity-icon',
    },
    {
      title: t('fast-settlement'),
      subTitle: t(
        'same-day-crypto-fiat-settlements-for-the-u-s-and-next-day-for-eu-asia-nearly-instant-settlements-for-crypto-crypto-trades',
      ),
      imgPath: '/res/img/home/settlement-icon.svg',
      altText: 'settlement-icon',
    },
    {
      title: t('trading-expertise'),
      subTitle: t(
        'our-experienced-trading-team-meets-and-exceeds-the-needs-of-each-client-electronic-otc-apis-are-available-for-institutions',
      ),
      imgPath: '/res/img/home/pro-icon.svg',
      altText: 'pro-icon',
    },
    {
      title: t('best-support'),
      subTitle: t(
        'thank-you-for-choosing-legend-trading-we-promise-an-efficient-customer-on-boarding-process-and-responsive-customer-support',
      ),
      imgPath: '/res/img/home/support-icon.svg',
      altText: 'support-icon',
    },
  ])
  const numberList = computed(() => [
    {
      // title: "$1B+",
      prefix: '',
      startVal: 0,
      endVal: 40,
      suffix: '+',
      subTitle: t('top-partners'),
    },
    {
      startVal: 0,
      endVal: 900,
      suffix: '+',
      subTitle: t('institutional-clients'),
    },
    {
      startVal: 0,
      endVal: 150,
      suffix: '+',
      subTitle: t('countries'),
    },
  ])
</script>
<style module lang="scss">
  .link {
    background-image: linear-gradient(to right, colors(light-gray), colors(light-gray) 50%, colors(theme) 50%);
    background-clip: text;
    background-position: -100%;
    background-size: 200% 100%;
    color: colors(theme);
    position: relative;
    font-size: 1.25rem;
    height: 2rem;
    line-height: 2rem;
    margin: 1.25rem;
    text-align: center;
    transition: all 0.2s ease-in-out;
    // -webkit-text-fill-color: transparent;

    &:before {
      content: '';
      background: colors(light-gray);
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0;
      height: 3px;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      background-position: 0;
    }

    &:hover::before {
      width: 100%;
    }
  }
</style>
