<template>
  <div class=" tw-flex tw-flex-col">
    <div align-self="center">
      <NuxtImg :src="imgSrc" class="tw-rounded-2xl tw-object-cover tw-w-full tw-h-[280px]" @click.native="onClick" />
    </div>
    <div class="tw-py-2 d-flex flex-column tw-text-dark" :cols="12">
      <p class="tw-flex tw-flex-wrap tw-mt-2 tw-mb-2 tw-leading-7">
        <template v-if="Array.isArray(article.category)">
          <span class="tw-bg-primary tw-font-semibold tw-text-sm tw-rounded-md tw-inline-flex tw-leading-7 tw-self-center tw-px-3 tw-mr-2" v-for="item in article.category">
            {{ typeof item === 'string' ? item : item.$.term }}
          </span>
        </template>
      </p>
      <h3 class="tw-text-left tw-font-bold tw-text-[1.25rem] tw-leading-tight tw-my-2 tw-line-clamp-3">
        {{ article.title }}
      </h3>
      <p class="tw-text-left">
        <a
          :href="props.article._path.replace(/^\/articles\/repost/, '/newsroom')"
          variant="text"
          class="tw-text-lg tw-text-primary tw-underline tw-underline-offset-2 hover:tw-text-black"
          @click.prevent="onClick">Read Article</a
        >
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps(['article', 'index'])
  const emit = defineEmits<{
    'view-article': [{ slug: string }]
  }>()

  const imgSrc = computed(() => {
    if (typeof props.article?.image === 'string') {
      return props.article?.image
    } else {
      return props.article?.image?.src
    }
  })

  const onClick = async () => {
    const slug = props.article._path.split('/').slice(3)

    emit('view-article', { slug })
  }

</script>

<style scoped>
</style>
